/* client/src/components/MealPlanner.css */

.mealplanner-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.app-header {
  text-align: center;
  margin-bottom: 30px;
}

.app-header h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.app-header h2 {
  font-size: 1.2em;
  color: #555;
}

.invite-screen,
.auth-choice-screen,
.signup-screen,
.signin-screen,
.setup-family-screen,
.signup-success-screen {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.invite-screen h1,
.auth-choice-screen h1,
.signup-screen h1,
.signin-screen h1,
.setup-family-screen h1,
.signup-success-screen h1 {
  text-align: center;
  margin-bottom: 20px;
}

.auth-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-button {
  background-color: #2c3e50;
  color: #fff;
  padding: 12px 24px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-button:hover:not(:disabled) {
  background-color: #1a242f;
}

.google-auth-button {
  background-color: #db4437;
}

.google-auth-button:hover:not(:disabled) {
  background-color: #a33c2e;
}

.back-button {
  background: none;
  border: none;
  color: #3498db;
  cursor: pointer;
  margin-top: 20px;
  font-size: 14px;
}

.toggle-auth {
  text-align: center;
  margin-top: 15px;
}

.toggle-button {
  background: none;
  border: none;
  color: #3498db;
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
}

.error-message {
  color: #e74c3c;
  margin-top: 10px;
  text-align: center;
}

.family-section {
  margin-bottom: 20px;
}

.family-section h2 {
  margin-bottom: 10px;
}

.family-member-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.family-member-row input[type="text"],
.family-member-row select {
  padding: 8px;
  margin-right: 10px;
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.gender-toggle {
  margin-right: 10px;
}

.gender-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
}

.gender-icon {
  color: #2c3e50;
}

.dietary-requirement select {
  width: 200px;
}

.remove-member-button {
  background: none;
  border: none;
  color: #e74c3c;
  cursor: pointer;
  font-size: 1.2em;
}

.add-member-button {
  background-color: #27ae60;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.add-member-button:hover {
  background-color: #1e8449;
}

.save-family-button {
  background-color: #2980b9;
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
}

.save-family-button:hover:not(:disabled) {
  background-color: #1c5980;
}

.mealplanner-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.mealplanner-loading p {
  font-size: 18px;
  color: #2c3e50;
  margin-top: 10px;
}

.health-check-button {
  background-color: #8e44ad;
  color: #fff;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
}

.health-check-button:hover {
  background-color: #71368a;
}
