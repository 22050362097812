/* src/components/login/login.css */

.auth-container {
    max-width: 500px;
    margin: 80px auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .auth-logged-in,
  .auth-forms {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .auth-logged-in h2 {
    margin-bottom: 20px;
    color: #333333;
  }
  
  .logout-button {
    padding: 10px 20px;
    background-color: #cc0000;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s ease-in-out;
  }
  
  .logout-button:hover,
  .logout-button:focus {
    background-color: #ff3333;
  }
  
  .auth-forms {
    width: 100%;
  }
  
  .form-toggle {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .toggle-button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    padding: 10px 20px;
    transition: color 0.2s ease-in-out;
    color: #555555;
  }
  
  .toggle-button.active {
    color: #0066cc;
    border-bottom: 2px solid #0066cc;
  }
  
  .toggle-button:disabled {
    cursor: default;
  }
  
  .auth-form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .auth-form h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333333;
  }
  
  .form-group {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    margin-bottom: 8px;
    font-weight: 500;
    color: #555555;
  }
  
  .form-group input {
    padding: 10px 12px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .form-group input:focus {
    border-color: #0066cc;
    outline: none;
  }
  
  .error-message {
    margin-bottom: 16px;
    padding: 10px;
    background-color: #ffe6e6;
    color: #cc0000;
    border: 1px solid #cc0000;
    border-radius: 4px;
    width: 100%;
    text-align: center;
  }
  
  .auth-button {
    padding: 12px;
    font-size: 16px;
    background-color: #0066cc;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    margin-bottom: 16px;
  }
  
  .auth-button:hover,
  .auth-button:focus {
    background-color: #005bb5;
  }
  
  .google-auth-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    color: #444444;
    border: 1px solid #cccccc;
    padding: 12px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    margin-bottom: 16px;
  }
  
  .google-auth-button:hover,
  .google-auth-button:focus {
    background-color: #f0f0f0;
  }
  
  .google-icon {
    margin-right: 8px;
    font-size: 20px;
  }
  
  .divider {
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    margin: 16px 0;
  }
  
  .divider::before,
  .divider::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #cccccc;
  }
  
  .divider:not(:empty)::before {
    margin-right: 8px;
  }
  
  .divider:not(:empty)::after {
    margin-left: 8px;
  }
  
  .auth-switch {
    text-align: center;
    color: #555555;
  }
  
  .switch-button {
    background: none;
    border: none;
    color: #0066cc;
    cursor: pointer;
    text-decoration: underline;
    font-size: 16px;
  }
  
  .switch-button:hover,
  .switch-button:focus {
    color: #005bb5;
  }
  
  @media (max-width: 480px) {
    .auth-container {
      margin: 40px 20px;
      padding: 16px;
    }
  
    .auth-container h2 {
      font-size: 24px;
    }
  
    .auth-button,
    .google-auth-button {
      font-size: 14px;
      padding: 10px;
    }
  
    .google-icon {
      font-size: 18px;
    }
  
    .toggle-button {
      font-size: 16px;
      padding: 8px 16px;
    }
  }
  